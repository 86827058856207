import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { i18n } from '@/languages/i18n'
import { yearDropdownPlugin } from '@/constants/yearDropdownPlugin'
dayjs.extend(utc)
dayjs.extend(timezone)
const config = {
  locale: Japanese,
  wrap: true,
  disableMobile: true,
}
const defaultMinDate = '1900-01'
export function getBirthdayConfig(initialDate) {
  return {
    locale: Japanese,
    wrap: true,
    disableMobile: true,
    minDate: defaultMinDate, // 1900年まで選択できる（固定。バリデーションに合わせる。）
    maxDate: Date.now(), // 未来日が選択できない

    plugins: [
      yearDropdownPlugin({
        yearStart: 1900,
        yearEnd: 0,
        fixedStartYear: true,
        date: new Date(initialDate), // Pass the initial date to the plugin
      }),
    ],
  }
}
export function configYM(initialDate, text: string = 'configYM') {
  return {
    locale: Japanese,
    wrap: true,
    disableMobile: true,
    minDate: defaultMinDate, // 1900年まで選択できる（固定。バリデーションに合わせる。）

    plugins: [
      monthSelectPlugin({
        // 2022/09/27 PhucNV Datepicker format will be different in placeholder UAT#4493: SG43(Ph2-R20220930)
        dateFormat: 'Y/m', //defaults to "Y-m-d"
        altFormat: 'F Y', //defaults to "F Y"
      }),
      yearDropdownPlugin({
        text: text,
        yearStart: 1900,
        yearEnd: 0,
        fixedStartYear: true,
        alwaysShowNextBtn: true,
        date: new Date(initialDate), // Pass the initial date to the plugin
      }),
    ],
  }
}

export function configYMPortfolio(initialDate, text: string = 'configYM') {
  const currentDate = new Date()
  const maxDate = new Date()
  maxDate.setFullYear(currentDate.getFullYear() + 10)

  return {
    locale: Japanese,
    wrap: true,
    disableMobile: true,
    minDate: defaultMinDate, // 1900年まで選択できる（固定。バリデーションに合わせる。）
    maxDate: maxDate,

    plugins: [
      monthSelectPlugin({
        dateFormat: 'Y/m', //defaults to "Y-m-d"
        altFormat: 'F Y', //defaults to "F Y"
      }),
      yearDropdownPlugin({
        text: text,
        yearStart: 1900,
        yearEnd: 10,
        fixedStartYear: true,
        date: new Date(initialDate), // Pass the initial date to the plugin
      }),
    ],
  }
}

export const configMinDate = {
  locale: Japanese,
  wrap: true,
  minDate: 'today',
  disableMobile: true,
}

export default config

export const ConvertFromUTCTime = (time: any) => {
  return dayjs(new Date(time))
}

export const ConvertFromLocalTime = (time: any) => {
  return dayjs(time).format('YYYY/MM/DD HH:mm:ss')
}
export const ChangeTime = (time: any) => {
  const newTime = ConvertFromLocalTime(time)
  const date = dayjs(new Date())
  let second = date.diff(dayjs(newTime), 'second')
  if (second < 0) second = 0
  const minute = date.diff(dayjs(newTime), 'minute')
  const hour = date.diff(dayjs(newTime), 'hour')
  const day = date.diff(dayjs(newTime), 'day')
  if (second < 60) {
    return second + '秒前'
  } else if (second < 3600) {
    return minute + '分前'
  } else if (second < 86400) {
    return hour + '時間前'
  } else if (second < 604800) {
    return day + '日前'
  } else {
    return dayjs(time).format('YYYY/MM/DD HH:mm')
  }
}

export const ConvertYearMonthDay = (time: any) => {
  return time ? dayjs(new Date(time)).format('YYYY/MM/DD') : ''
}

export const convertDateToJapanese = (time: any) => {
  let timeConvert = []
  if (i18n.global.locale.value === 'jp') {
    // @ts-ignore
    time.map((item: any) => timeConvert.push(dayjs(item).format('MM月DD日')))
  } else {
    // @ts-ignore
    time.map((item: any) => timeConvert.push(dayjs(item).format('MM/DD')))
  }
  return timeConvert
}

export const convertDateChartToJapanese = (time: any) => {
  let timeConvert = []
  if (i18n.global.locale.value === 'jp') {
    // @ts-ignore
    time.map((item: any) => timeConvert.push(dayjs(item).format('M月D日')))
  } else {
    // @ts-ignore
    time.map((item: any) => timeConvert.push(dayjs(item).format('M/D')))
  }
  return timeConvert
}

export const setDate = (number: number) => {
  const dateToday = new Date()
  return dayjs(dateToday.setDate(dateToday.getDate() - number)).format('YYYY/MM/DD')
}

// 2022/10/03 CW-TrungNT Fix for UAT #4555, #4553: SG30, SL30, SG43, SG40(Ph1-R20221005)
export const displayWorkingPeriod = (startDate: string, endDate?: string) => {
  const workingStartDate = dayjs(startDate).format('YYYY/M')
  const workingEndDate = getWorkingEndDate(startDate, endDate)
  const workingPeriod = getWorkingPeriod(startDate, endDate)

  return `${workingStartDate}〜${workingEndDate}   ${workingPeriod}`
}

export const rangeMonth = (startDate: string | Dayjs, endDate: string | Dayjs) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Calculate the month difference, ignoring the days.
  const monthsDiff = (end.year() - start.year()) * 12 + (end.month() - start.month() + 1)

  return monthsDiff
}

// 2022/10/03 CW-TrungNT Fix for UAT #4555, #4553: SG30, SL30, SG43, SG40(Ph1-R20221005)
export const getWorkingPeriod = (startDate: string, endDate?: string) => {
  const endDateObj = !!endDate ? dayjs(endDate) : dayjs(new Date())
  const startDateObj = dayjs(startDate)

  if (!startDateObj.isValid() || !endDateObj.isValid()) {
    return ''
  }

  if (startDateObj.isAfter(endDateObj)) {
    return ''
  }

  const monthCount = rangeMonth(startDateObj, endDateObj)
  const years = Math.floor(monthCount / 12)
  const months = monthCount - years * 12

  if (years >= 1) {
    return `（${years}年${months}ヶ月）`
  } else {
    return `（${months}ヶ月）`
  }
}

// 2022/10/03 CW-TrungNT Fix for UAT #4555, #4553: SG30, SL30, SG43, SG40(Ph1-R20221005)
export const getWorkingEndDate = (workingStartDate: string, workingEndDate?: string) => {
  let workingEndDateObj = dayjs(workingEndDate)
  if (workingEndDateObj.isValid()) {
    // working end date is valid date
    return workingEndDateObj.format('YYYY/M')
  } else if (workingEndDate) {
    // working end date is invalid date
    return ''
  } else {
    // working end date is empty
    workingEndDateObj = dayjs(new Date())
    const workingStartDateObj = dayjs(workingStartDate)

    return workingStartDateObj.isAfter(workingEndDateObj) ? '' : '現在'
  }
}
